import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { pcFitmentManagerActions, pcFitmentManagerSelectors } from '../core/PCFitmentManager';


class Index extends React.Component {
  componentDidMount() {
    const { storeHash, actions } = this.props;

    if (storeHash) actions.fetchAccount(storeHash);
  }

  static getInitialProps({ reduxStore, req, query }) {
    const isServer = !!req;

    // storeHash should have been passed via query on app load
    // console.log(query);

    return query;
  }

  render () {
    const { account, storeHash } = this.props;
    return (
      <Card style={{ maxWidth: 360 }}>
        <CardMedia
          className="media"
          image="https://bc.partsconnect.co/static/pc-logo.png"
          title="Parts Connect"
          style={{
            marginTop: '5px',
            height: 60,
            backgroundSize: 'contain',
            backgroundRepeat: 'none',
          }}
        />
        { storeHash ?
          <CardContent>
            <Typography gutterBottom variant="headline" component="h2">
              Your account is active.
            </Typography>
            { storeHash && <Typography component="p">Your store hash is: {storeHash}</Typography> }
            { typeof account === 'object' &&
              <div>
                { account.tenantId && <Typography component="p">Your tenant ID is: {account.tenantId}</Typography> }
                { account.bcContainerId && <Typography component="p">Your BC container ID is: {account.bcContainerId}</Typography> }
                { account.bcContainerClass && <Typography component="p">Your BC container class is: {account.bcContainerClass}</Typography> }
                { account.searchResultsItemCount && <Typography component="p">Your search results item count is: {account.searchResultsItemCount}</Typography> }
              </div>
            }
          </CardContent> : 
          <CardContent>
            <Typography component="p">Please select 'PC Fitment' from the Apps navigation menu to the left to reload this page.</Typography>
          </CardContent>
        }
      </Card>
    )
  }
}

Index.propTypes = {
  account: PropTypes.object,
  actions: PropTypes.object,  
};

function mapStateToProps(state) {
  return {
    account: pcFitmentManagerSelectors.accountSelector(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(pcFitmentManagerActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);