import {
  SET_ERROR,
  CLEAR_ERROR,

  FETCH_ACCOUNT_REQUEST,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_FAILURE,
} from './action-types';

const initialError = {
  hasError: false,
  message: '',
}

const initialState = {
  isFetching: false,
  error: { ...initialError },

  account: {
    tenantId: undefined,
  },
};

export default function(state=initialState, action) { 
  switch (action.type) {
    case SET_ERROR: 
      let { message } = action.payload;

      return {
        ...state,
        error: {
          hasError: true,
          message,
        }
      }

    case CLEAR_ERROR:
      return {
        ...state,
        error: { ...initialError }
      }

    case FETCH_ACCOUNT_REQUEST:
      return {
        ...state,
        error: { ...initialError },
      };

    case FETCH_ACCOUNT_SUCCESS:
      return {
        ...state,
        error: { ...initialError },
        account: action.payload.data, 
      };

    case FETCH_ACCOUNT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

   default:
      return state;
  }
}
