import {
  SET_ERROR,
  CLEAR_ERROR,

  FETCH_ACCOUNT_REQUEST,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_FAILURE,
} from './action-types';

import {
} from './constants';

import { pcfApi } from '../api';

export function setError(message) {
  return {
    type: SET_ERROR,
    payload: {
      message,
    }
  }
}

export function clearError() {
  return {
    type: CLEAR_ERROR,
  }
}

/* FETCH ACCOUNT */

export function fetchAccountRequest() {
  return {
    type: FETCH_ACCOUNT_REQUEST,
  }
}

export function fetchAccountSuccess(data) {
  return {
    type: FETCH_ACCOUNT_SUCCESS,
    payload: {
      data,
    }
  }
}

export function fetchAccountFailure(error) {
  return {
    type: FETCH_ACCOUNT_FAILURE,
    payload: {
      error,
    }
  }
}

export function fetchAccount(storeHash) {
  console.log(' pcfApi storeHash:: '+storeHash);
  return dispatch => {
    dispatch(fetchAccountRequest());
    return pcfApi(`/account/${encodeURIComponent(storeHash)}`)
      .then(res => dispatch(fetchAccountSuccess(res)))
      .catch(err => dispatch(fetchAccountFailure(err)));
  }
}
